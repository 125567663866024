<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-03-24 10:42:40
-->
<template>
  <el-dialog :visible.sync="sucShow"
             width="30%"
             center
             :before-close="handleClose">
    <div class="tit">
      <i class="el-icon-success"></i>
      <div>{{ $t('w_0158') }}</div>
    </div>
    <div class="digBody">
      <div class="red">{{ $t('w_0159') }}
        <div class="copy"
             @click="onCopy">{{ $t('MY_CK_27') }}</div>
      </div>
      <!-- <div>{{$t('CK_KS_14')}}: {{ allData.memberName }}</div>
      <div>{{ $t('N_I_124') }}: {{ allData.memberCode }}</div>
      <div>{{ $t('w_0160') }}: {{ allData.phone }}</div>
      <div>{{$t('w_0041')}}: {{ allData.loginPassword }}</div>
      <div>{{ $t('N_I_217') }}: {{ allData.payPassword }}</div>
      <div>{{ $t('PER_DA_44') }}: {{ allData.email }}</div>
      <div>{{ $t('w_0161') }}: {{ allData.urlAddress }}</div> -->
      <div class="txt">
      <el-descriptions title="" column="1">
    <el-descriptions-item :label="$t('CK_KS_14')">{{ allData.memberName }}</el-descriptions-item>
    <el-descriptions-item :label="$t('N_I_124')">{{ allData.memberCode }}</el-descriptions-item>
    <el-descriptions-item :label="$t('w_0160')">{{ allData.phone }}</el-descriptions-item>
    <el-descriptions-item :label="$t('w_0041')">{{ allData.loginPassword }}</el-descriptions-item>
    <el-descriptions-item :label="$t('N_I_217')">{{ allData.payPassword }}</el-descriptions-item>
    <!-- <el-descriptions-item :label="$t('PER_DA_44')">{{ allData.email }}</el-descriptions-item> -->
    <el-descriptions-item :label="$t('w_0161')">{{ allData.urlAddress }}</el-descriptions-item>
    <!-- <el-descriptions-item label="公众号搜索">{{ allData.gzh }}</el-descriptions-item> -->
    <!-- <el-descriptions-item label="APP(苹果)">{{ allData.ios }}</el-descriptions-item> -->
    <!-- <el-descriptions-item label="APP(安卓)">{{ allData.android }}</el-descriptions-item> -->
</el-descriptions>
</div>
    </div>
  
    <span slot="footer"
          class="dialog-footer">
      <div class="btn"
           @click="handleClose">{{ this.$t('w_0035')  }}</div>
      <div class="hui"
           @click="handleClose">{{ $t('ENU_P_TYPE0') }}</div>
    </span>
    <img class="yh"
         v-show="bia"
         src="@/assets/images/yh.gif"
         alt="">
    <img class="cd"
         v-show="bia"
         src="@/assets/images/cd.gif"
         alt="">
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      sucShow: false,
      bia: false,
      allData: '',
    }
  },
  methods: {
    showSuccess(data) {
      this.allData = data
    //   this.allData.gzh = '海之圣'
    // this.allData.ios = 'http://yxydl.hzs413.com/q2efum'
    // this.allData.android = 'https://cn.hzs413.com/'
      this.sucShow = true
      this.bia = true
      let that = this
      setTimeout(() => {
        that.bia = false
      }, 3000)
    },
    handleClose() {
      this.sucShow = false
      this.$emit('successClose')
    },
    payPw() {},
    onCopy() {
      let text =
        this.$t('CK_KS_14') + ':' +
        this.allData.memberName +
        '\n' +
        `${this.$t('N_I_124')}:` +
        this.allData.memberCode +
        '\n' +
        this.$t('w_0160') +':' +
        this.allData.phone +
        '\n' +
        this.$t('w_0041') +':' +
        this.allData.loginPassword +
        '\n' +
        this.$t('N_I_217')+':' +
        this.allData.payPassword +
        '\n' +
        this.$t('w_0161')+':' +
        this.allData.urlAddress
		// +
        // '\n' +
        // this.$t('APP_ADD_7')+':' +
        // this.allData.gzh+
        // '\n' +
        // 'APP'+`(${this.$t('APP_ADD_8')})` +
        // ':' +
        // this.allData.ios+
        // '\n' +
        // 'APP'+`(${this.$t('APP_ADD_9')})` +
        // ':' +
        // this.allData.android

      this.$copyText(text).then(
        (e) => {
          this.$message({
            message: this.$t('MY_CK_29'),
            type: 'success',
          })
        },
        (e) => {
          console.log('复制失败：', e)
        }
      )
      // const input = document.createElement('textarea')
      // input.value = this.copyContext
      // document.body.appendChild(input)
      // input.select()
      // document.execCommand('Copy')
      // this.$message.success('复制成功')
      // input.remove()
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
}
.btn {
  line-height: 50px;
  width: 120px;
  height: 50px;
  background: #d5251d;
  border-radius: 6px 6px 6px 6px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin: 25px auto;
  cursor: pointer;
}
.hui {
  line-height: 50px;
  width: 120px;
  height: 50px;
  background: #ccc;
  border-radius: 6px 6px 6px 6px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin: 25px auto;
  cursor: pointer;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.tit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #333;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 15px;
  .el-icon-success {
    color: #009900;
    font-size: 28px;
    margin-right: 10px;
  }
}
.digBody {
  margin-top: 30px;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  .red {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #f43c3a;
    position: relative;
    line-height: 30px;
    height: 30px;
    .copy {
      position: absolute;
      right: 0;
      transform: translateX(-500%);
      top: 0;
      font-size: 14px;
      line-height: 30px;
      height: 30px;
      color: #333;
      cursor: pointer;
    }
  }
  div {
    margin-bottom: 5px;
  }
}
.yh {
  width: 262px;
  height: 262px;
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.cd {
  width: 496px;
  height: 496px;
  position: absolute;
  bottom: 0;
}
.txt{
  width: 60%;
  margin: 0 auto;
}
</style>